$font-stack: 'Raleway', sans-serif;
$blue: #00A1D5;
$grey: #b9b9b9;

@import 'navbar';
@import 'hero';
@import 'about';
@import 'features';
@import 'projects';
@import 'testimonial';
@import 'contact';
@import 'single';
@import 'footer';
@import 'jobs';

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200 800;
  src: url(../webfonts/raleway.woff2) format('woff2');
}

body {
  font-family: $font-stack;
  line-height: 1.5em;
}

.title {
  font-weight: 200;
  margin-top: 0px;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.title:after {
  content: "";
  position: absolute;
  border-top: 1px solid $grey;
  left: calc(50% - 40px);
  bottom: 0;
  width: 80px;
  height: 0px;
}

h1 {
  line-height: 1em;
}
h2 {
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  margin: auto;
  max-width: 1280px;
}