#footer {
  background-color: #353535;
  color: white;
  padding: 1rem;
  .container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 400px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 400px) {
      img {
        padding: 0px 10px 0px 10px;
      }
    }
  }
}