#testimonial {
  background-color: $blue;
  text-align: center;
  padding: 50px;
  .rating {
    color: #FFCD00;
    font-size: 1.5em;
  }
  p {
    color: white;
    font-size: 1.5em;
    font-weight: 200;
    line-height: 1.5em;
  }
  .author {
    font-size: 1.2em;
  }
  ul {
    width: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;
    padding: 0;
    list-style: none;
    display: flex;
  }
  li {
    width: 100%;
  }
  li:first-child {
    transform:translateX(0);
  }
  li:nth-child(2) {
    transform:translateX(+100%);
    margin-left: -100%; 
  }
  // li:nth-child(3) {
  //   transform:translateX(+100%);
  //   margin-left: -100%; 
  // }
  .ease-in {
    -webkit-animation: slide-left 1.0s forwards; /* for less modern browsers */
    animation: slide-left 1.0s forwards;
  }
  .ease-out {
    -webkit-animation: slide-right 1.0s forwards; /* for less modern browsers */
    animation: slide-right 1.0s forwards;
  }
  
  @keyframes slide-left {
    from {
      transform:translateX(+100%);
    }
    to {
      transform:translateX(0);
    }
  }
  @keyframes slide-right {
    from {
      transform:translateX(0);
    }
    to {
      transform:translateX(-100%);
    }
  }

}



