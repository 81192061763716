#contact {
  // background-color: white;
  background-color: #4E4E4E;
  color: white;
  .single-content {
    padding: 0px 20px 20px 20px;
  }
  .top {
    padding: 20px 20px 50px 20px;
    .sub {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .subleft {
        margin-right: 20px;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  #contact {
    .top {
      display: flex;
      justify-content: space-evenly;
    }
  }
}