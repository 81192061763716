#hero {
  background-color: black;
  color: white;
  // width: 100vw;
  height: calc(100vh - 60px);
  overflow: hidden;

  /* Flexbox stuff */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  text-align: center;

  /* Background styles */
  background-image: linear-gradient(rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05)), url('../images/background.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  h1 {
    font-size: 5em;
    font-weight: 800;
    letter-spacing: 1em;
    text-indent: 1em;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 2em;
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    color: $blue;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0em;
  }
  h3 {
    font-size: 1.2em;
    // margin-top: 0em;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0em;
    margin-bottom: 7em;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      margin-bottom: 0.3em;
    }
    h2{
      letter-spacing: 0.01em;
      text-indent: 0.01em;
    }
    h3 {
      margin-bottom: 5em;
    }
  }
  .button {
    text-transform: uppercase;
    color: white;
    background-color: $blue;
    padding: 20px 40px;
    /* Border styles */
    border: 0px solid black;
    border-radius: 50px;
    // margin-bottom: auto;
  }
  i {
    font-size: 8em;
    padding: 0px 0px 50px 0px;
  }
}

#hero-inner {
   /* Flexbox stuff */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}