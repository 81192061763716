.single-section {
  background-color: #EBEBEB;
  padding: 0px 20px 50px 20px;
}

.single-content {
  img {
    max-width: 100%;
    // float: left;
  }
  a {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 800px) {
  .single-content {
    img {
      max-width: 50%;
      // float: left;
    }
  }
}