#features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.feature {
  max-width: 340px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  h2 {
    // margin-top: 40px;
  }
  p {
    margin-bottom: 36px;
  }
  .button {
    margin: 5px;
    color: $blue;
    background-color: white;
    padding: 10px 20px;
    /* Border styles */
    border: 1px solid $blue;
    border-radius: 50px;
    // margin-bottom: auto;
  }
  .primary {
    color: white;
    background-color: $blue;
  }
}


.diamond {
  margin: auto;
  // width: 125px;
  // height: 125px;
  // transform:rotate(45deg);
  // border: 1px solid $grey;
  text-align: center;
  i {
    font-size: 5em;
    color: $blue;
    // transform: rotate(-45deg);
    vertical-align: middle;
    margin-top: 25px;
    // width: 80px;
    // height: 80px;
  }
}