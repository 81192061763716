#projects-section {
  background-color: #EBEBEB;
}
#projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px 5px;
  a {
    display: inline;
  }
}

.project {
  position:relative;
  margin: 5px;
  @media only screen and (min-width: 800px) {
    width: calc(33% - 10px);
  }
  @media only screen and (max-width: 800px) {
    width: calc(50% - 10px);
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
  img {
    width: 100%;
    vertical-align:top;
  }
}

#referenties {
  .project:after, .project:before {
    position: absolute;
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .project:after {
    content: '\A';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
  }
  .project:before {
    content: attr(data-content);
    width: 100%;
    color: #fff;
    z-index: 1;
    bottom: 0;
    padding: 4px 10px;
    text-align: center;
    background: $blue;
      box-sizing: border-box;
      -moz-box-sizing:border-box;
  }
  .project:hover:after {
    background: rgba(255,255,255,0.1);
  }
}